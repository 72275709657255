import { captureRemixErrorBoundaryError } from "@sentry/remix";
// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";

import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { create } from "zustand";
import { BookingWithProperty } from "./dto/booking.dto";
import { Message } from "./dto/message.dto";
import { User } from "./dto/user.dto";
import { I18nProvider } from "./i18nContext";

interface LocalStore {
  user?: User;
  setUser: (u: User | undefined) => void;
  booking?: BookingWithProperty;
  setBooking: (b: BookingWithProperty | undefined) => void;
  conv?: Message[];
  setConv: (c: Message[]) => void;
  propertyName?: string;
  setPropertyName: (p: string | undefined) => void;
  localMessages: Message[];
  setLocalMessages: (m: Message[]) => void;
  addLocalMessage: (m: Message) => void;
  searchParam?: string;
  setSearchParam: (s: string) => void;
  totalBookings?: number;
  setTotalBookings: (p: number | undefined) => void;
  mauriceTyping: boolean;
  setMauriceTyping: (m: boolean) => void;
  progress: boolean;
  setProgress: (p: boolean) => void;
}

export const useBookingStore = create<LocalStore>()((set) => ({
  user: undefined,
  setUser: (u) => set({ user: u }),
  booking: undefined,
  setBooking: (b) => set({ booking: b }),
  conv: undefined,
  setConv: (c) => set({ conv: c }),
  propertyName: undefined,
  setPropertyName: (p) => set({ propertyName: p }),
  localMessages: [],
  setLocalMessages: (m) => set({ localMessages: m }),
  addLocalMessage: (m) =>
    set((state) => ({
      localMessages: state.localMessages.concat(m),
    })),
  searchParam: "",
  setSearchParam: (s) => set({ searchParam: s }),
  totalBookings: undefined,
  setTotalBookings: (p) => set({ totalBookings: p }),
  mauriceTyping: false,
  setMauriceTyping: (m) => set({ mauriceTyping: m }),
  progress: true,
  setProgress: (p) => set({ progress: p }),
}));

// const themeLight = createTheme({
//   components: {
//     Button: Button.extend({
//       defaultProps: {
//         // variant: "gradient",
//         color: "blue.5"
//       },
//     }),
//   },
//   defaultGradient: {
//     from: "blue",
//     to: "orange",
//   },
// });

// const themeDark = createTheme({
//   components: {
//     Button: Button.extend({
//       defaultProps: {
//         variant: "gradient",
//       },
//     }),
//   },
//   defaultGradient: {
//     from: "blue.6",
//     to: "orange.6",
//   },
// });

// never put it inside the component, it will break the hot reload
const queryClient = new QueryClient();

export function Layout({ children }: { children: React.ReactNode }) {
  // const colorScheme = useColorScheme();

  return (
    <html lang="en">
      <head>
        <link rel="icon" href="/mauriceLogo.svg" />
        <title>Maurice Assistant</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body>
        <I18nProvider>
          <QueryClientProvider client={queryClient}>
            <MantineProvider
              // theme={colorScheme === "light" ? themeLight : themeDark}
              defaultColorScheme="auto"
            >
              <Notifications />
              <ModalsProvider>{children}</ModalsProvider>
            </MantineProvider>
          </QueryClientProvider>
        </I18nProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
